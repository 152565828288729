import * as React from "react"
import {graphql} from "gatsby";
import {FaRegClock, FaUserFriends} from "react-icons/fa";
import Layout from "../components/layout";

// markup
const IndexPage = ({ data }) => {
  const recipe = data.allRecipe.edges.map(edge => edge.node)[0];
  return (
    <Layout title={recipe.title}>
      <div className={"grid grid-cols-1 sm:grid-cols-2 gap-8"}>
        <div className={"flex justify-center"}>
          <img src={recipe.image} alt={recipe.title} className={"object-cover h-48 w-96"} />
        </div>
        <div className={"flex flex-grow self-stretch flex-col justify-center items-center"}>
          <h1 className={"text-center"}>{recipe.title}</h1>
          {
            recipe.sourceUrl && recipe.sourceName &&
            <div>By <a href={recipe.sourceUrl}>{recipe.sourceName}</a></div>
          }
          <div className={"mt-2 flex gap-8 text-secondary"}>
            <div className={"flex gap-2 items-center"}><FaUserFriends/>{recipe.servings} Servings</div>
            <div className={"flex gap-2 items-center"}><FaRegClock/>{recipe.readyInMinutes} Minutes</div>
          </div>
        </div>
        <div>
          <h2>Ingredients:</h2>
          <ul className={""}>
            { recipe.extendedIngredients.map((ingredient, i) => (
              <li key={`ingredient-${i}`} className={"my-1"}>{ingredient.original}</li>
            ))}
          </ul>
        </div>
        <div>
          <h2>Instructions:</h2>
          <div dangerouslySetInnerHTML={{ __html: recipe.instructions }}/>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      buildTime
    }
    allRecipe {
    edges {
      node {
        id
        title
        instructions
        summary
        image
        sourceUrl
        sourceName
        servings
        readyInMinutes
        extendedIngredients {
          original
        }
      }
    }
  }
  }
`

export default IndexPage
