import * as React from "react"
import {FaUtensils} from "react-icons/fa";
import {graphql, Link, useStaticQuery} from "gatsby";
import Seo from "./seo";
import {StaticImage} from "gatsby-plugin-image";
import dayjs from "dayjs";

const Layout = ({ title, description, children }) => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        buildTime
      }
    }
  `);
  const buildTime = dayjs(data.site.buildTime).format('YYYY-MM-DD HH:mm:ss')
  return (
    <>
    <Seo title={title} description={description}/>
    <div className={"min-h-screen h-screen flex flex-col"}>
      <header className={"max-w-screen-md mx-auto py-6 px-4 flex justify-center"}>
        <Link to={"/"}>
          <div className={"flex items-center gap-4"}>
            <FaUtensils className={"text-6xl"}/>
            <div>
              <h1 className={"font-serif font-bold mb-0"}>OneRecipePerDay</h1>
              <p className={"text-secondary"}>Nothing more, nothing less.</p>
            </div>
          </div>
        </Link>
      </header>
      <main className={"max-w-screen-md mx-auto py-12 px-4 flex-grow"}>
        { children }
      </main>
      <footer className={"relative mt-60 flex-grow-0"}>
        <StaticImage style={{position: "absolute"}} className={"bottom-[90%] left-4 md:left-12 lg:left-1/4"} src={"../images/messy-kitchen.svg"} alt={"messy kitchen"}/>
        <div className={"bg-primary text-secondary"}>
          <div className={"max-w-screen-md mx-auto flex flex-col items-center py-4 px-4"}>
            <p title={`Last updated at: ${buildTime}`}>Not what you're looking for? Come back tomorrow!</p>
            <p><a href={"https://marten.sigw.art"} target={"_blank"} rel={"noreferrer"}>Made with ❤ by Marten Sigwart️</a></p>
            <p className={"text-xs mt-2"}><a href='https://www.freepik.com/vectors/pot-icon'>Pot icon vector created by Olga_spb - www.freepik.com</a></p>
          </div>
        </div>
      </footer>
    </div>
    </>
  )
}

export default Layout
